@font-face {
    font-family: 'neue haas';
    src: url('../assets/fonts/NHaasGroteskTXPro-55Rg.ttf');
    font-weight: normal;
  }

//   @font-face {
//     font-family: 'neue haas';
//     src: url('../assets/fonts/NHaasGroteskTXPro-65Md.ttf');
//     font-weight: bold;
//   }

//   @font-face {
//     font-family: 'neue haas';
//     src: url('../assets/fonts/NHaasGroteskTXPro-75Bd.ttf');
//     font-weight: bolder;
//   }
  
    
  