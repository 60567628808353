// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ionic/angular/css/display.css";
@import 'tippy.js/dist/tippy.css';
@import "scss/__aio_components.scss";
@import "scss/__aio_fonts.scss";

// Mughees: Edit profile Phone and email modals

.phoneNumberModal .modal-wrapper {
    --height: 290px;
    --width: 350px;
}
.swiper-pagination-bullet.swiper-pagination-bullet{
    width: 6px;
    height: 6px;
    transition: all 0.3s ease-in-out;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
    width: 18px;
    border-radius: 17px;
}

ion-select{
    ion-select-option{
        font-family:var(--font-family);
    }
}

ion-modal.birthday-picker {
    background: transparent;

    @media only screen and (min-width: 300px) {
        --height: 395px;
        padding: 0 20px;
    }

    @media only screen and (min-width: 768px) and (min-height: 600px) {
        --height: 395px;
    }

    &::part(content) {
        --background: transparent;
    }
}

ion-modal.side-menu{
    --height: 100vh;
    --width: 600px;
    --border-radius: 0px;
    &::part(content) {
        position: absolute;
        right: 0;
    }
    @media (max-width: 39.9375em) {
        --height: 99vh;
        --width: 100vw;
        --background: white;
        --border-radius: 12px;
        &::part(content){
            position: absolute;
            bottom: -10px;
            transform: none;
        }
        ion-content{
            .mobilehandle{
                display: flex;
                justify-content: center;
                margin: 0 auto;
                font-size: 48px;
                transform: translateY(-12px) scale(0.68);
            }
        }
    }
}

ion-modal.side-menu2{
    --height: 100vh;
    --width: 600px;
    --border-radius: 0px;
    &::part(content) {
        position: absolute;
        right: 0;
    }
    @media (max-width: 39.9375em) {
        --height: 100vh;
        --width: 100vw;
        --background: white;
        --border-radius: 0px;
        ion-content{
            .mobilehandle{
                display: flex;
                justify-content: center;
                margin: 0 auto;
                font-size: 48px;
                transform: translateY(-12px) scale(0.68);
            }
        }
    }
}

ion-modal.TutorialMenu{
    --height: 100vh;
    --width: 600px;
    --border-radius: 0px;
    &::part(content) {
        position: absolute;
        right: 0;
    }
    @media (max-width: 39.9375em) {
        --height: 100vh;
        --width: 80vw;
        --background: white;
        --border-radius: 0px;
        ion-content{
            .mobilehandle{
                display: flex;
                justify-content: center;
                margin: 0 auto;
                font-size: 48px;
                transform: translateY(-12px) scale(0.68);
            }
        }
    }
}

ion-modal.iframemodal{
    --min-height: 100%;
    --min-width: 68%;
    iframe{
        width: 100%;
        height: 100%;
        border: 0;
    }

    .support-iframe-button {
        position: fixed;
        top:20px;
        left:20px;
        --background: transparent;
        --color: white;
        z-index: 999999999999;
    }
    .upgrade-iframe-button {
        position: fixed;
        top: 0px;
        left: 6px;
        --background: transparent;
        --color: var(--main-theme);
        --box-shadow: 0;
        z-index: 999999999999;
    }
    .support-iframe-button-mobile {
        --background: transparent;
        --color: var(--main-theme);
        z-index: 999999999999;
    }
    .upgrade-iframe-button-mobile {
        --background: transparent;
        --color: var(--main-theme);
        --box-shadow: 0;
        z-index: 999999999999;
    }
}

ion-modal.dialog{
    --height: auto;
    --width: 600px;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);   
    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 20px;
            a {
                color: #3880ff;
            }
        }
    }
}

  .tutorial{
    ion-item{
        ion-icon.ion-accordion-toggle-icon{
            display: none;
            @media (max-width: 39.9375em) {
                margin-left: 8px;
            }
        }
    }
}

/* Animated Sidebar CSS */
#main-content .ion-page {
    @media (min-width: 40em) {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform:translateZ(0);
        padding-left: 65px;
    }
    transition: .6s;
    &[start-page]{                    //attribute for pages that don't have side menu
        padding-left: 0px;
    }
}
.sideParent .sidemenu:hover{
    will-change: auto;
}
.sideParent:first-child:hover ~#main-content .ion-page {
    will-change: auto;
}
body.showMenu {
    .sidemenu {
        width: 240px;
        margin-top: 60px;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform:translate3d(0, 0, 0);
    }
    #main-content .ion-page {
        @media (min-width: 40em) {
            padding-left: 240px;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform:translate3d(0, 0, 0);
        }
        &[start-page]{                    //attribute for pages that don't have side menu
            padding-left: 0px;
        }
    }
}
.menu-content{
    margin-top: 0px;
    @media (min-width: 40em) {
        &.show{
            margin-top: 60px;
        }
    }
}

.range{
    ion-range{
        &::part(bar){
            width: 106%;
            left: -9px;
        }
        &::part(bar-active){
            left: -9px;
        }
        &::part(pin){
            padding: 30px 0px;
            background: var(--pin-background);
            color: var(--pin-color);
            &::before{
                background: var(--pin-background);
                color: var(--pin-color);
            }
        }
    }
}

.toggleslot ~ ion-icon{
    display: none;
}
ion-popover, ion-action-sheet, ion-modal{
    --backdrop-opacity:0.2;
}
ion-popover::part(content){
    border: 0px;
    min-width: fit-content;
}

ion-popover{
    ion-content{
        border-radius: 12px;
        overflow: hidden;
    }
}
my-profile{
    ion-content{
        --background: #E5E5E5;
    }
}
.no-ripple{
    --ripple-color: transparent;
}
.pointer{
    cursor: pointer;
}
ion-modal.phn-field{
    --max-width: 350px;
    --max-height: 280px;
    --border-radius:16px;
    --background: transparent;
    --box-shadow: none;
    // --width: 370px;
    // --max-height: 34vh;
    ion-header.phn-field {
        background: #fff;
        border: 1px solid #E6E6E6;
        ion-toolbar{
            --ion-color-base: #fff !important;
            --min-height: auto;
            padding-top: 24px !important;
            padding-bottom: 16px;
            padding-inline-start: 24px;
            padding-inline-end: 24px;
            --background: transparent;
            ion-title{
                padding: 0;
                font-size: 24px;
                font-weight: 400;
                color: #0D0D0D;
                letter-spacing: 0.25px;
            }
        }
        &:after{
            display: none;
        }
    }
    ion-content.phn-field{
        --padding-top: 24px;
        --padding-bottom: 24px;
        --padding-start: 24px;
        --padding-end: 24px;
        --background: transparent;
        scrollbar-width: thin;
        background: #fff;
        .field-main{
            position: relative;
            p.heading-text{
                margin: 0 0 8px 0;
                font-size: 12px;
                text-align: left;
            }
            p.text-alert{
                font-size: 16px;
                position: absolute;
                left: 0;
                top: 100%;
            }
    
            ion-item{
                --padding-start: 12px;
                --padding-end: 12px;
                --max-height: unset;
                --min-height: unset;
                border: 1px solid #e6e6e6;
                --border-width: 0;
                --padding-top: 11px;
                --padding-bottom: 11px;
                --border-style: none;
                width: 100%;
                ion-input{
                    --padding-top:0;
                    --padding-bottom:0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                   
                }
                ion-label{
                    display: none;
                }
                ion-intl-tel-input{
                    ionic-selectable{
                        padding: 0;
                    }
                }
            }
            
        }
        .btns-main{
            display: flex;
            align-items: center;
            justify-content: space-between;
            ion-button{
                margin: 32px 0 0 0;
                --background: transparent;
                --box-shadow: none;
                font-size: 16px;
                font-weight: 600;
                padding: 16px 0;
                width: 48%;
                height: unset;
                border-radius: 50px;
                --background-hover: transparent;
                text-transform: capitalize;
            }
            ion-button.d-btn{
                background-color: #179966;
                color: #fff;
                border: 1px solid #179966;
            }
            ion-button.c-btn{
                color:#0D0D0D;
                border: 1px solid #E6E6E6;
            }
        }
    }
}

.c-modal{
    --height: 530px;
    --max-width: 466px;
    --width: 466px;
    --background: transparent;
    --box-shadow: 0 0 0;
    --border-radius:16px;
    ion-header.phn-field {
        background: #fff;
        border: 1px solid #E6E6E6;
        ion-toolbar{
            --ion-color-base: #fff !important;
            --min-height: auto;
            padding-top: 24px !important;
            padding-bottom: 16px;
            padding-inline-start: 24px;
            padding-inline-end: 24px;
            --background: transparent;
            ion-title{
                padding: 0;
                font-size: 24px;
                font-weight: 400;
                color: #0D0D0D;
                letter-spacing: 0.25px;
            }
        }
        &:after{
            display: none;
        }
    }
    ion-content.phn-field{
        --padding-top: 24px;
        --padding-bottom: 24px;
        --padding-start: 24px;
        --padding-end: 24px;
        --background: transparent;
        scrollbar-width: thin;
        background: #fff;
        .field-main{
            position: relative;
            p.heading-text{
                margin: 0 0 8px 0;
                font-size: 12px;
                text-align: left;
            }
            p.text-alert{
                font-size: 16px;
                position: absolute;
                left: 0;
                top: 100%;
            }
    
            ion-item{
                --padding-start: 12px;
                --padding-end: 12px;
                --max-height: unset;
                --min-height: unset;
                border: 1px solid #e6e6e6;
                --border-width: 0;
                --padding-top: 11px;
                --padding-bottom: 11px;
                --border-style: none;
                width: 100%;
                ion-input{
                    --padding-top:0;
                    --padding-bottom:0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                   
                }
                ion-label{
                    display: none;
                }
                ion-intl-tel-input{
                    ionic-selectable{
                        padding: 0;
                    }
                }
            }
            
        }
        .btns-main{
            display: flex;
            align-items: center;
            justify-content: space-between;
            ion-button{
                margin: 32px 0 0 0;
                --background: transparent;
                --box-shadow: none;
                font-size: 16px;
                font-weight: 600;
                padding: 16px 0;
                width: 48%;
                height: unset;
                border-radius: 50px;
                --background-hover: transparent;
                text-transform: capitalize;
            }
            ion-button.d-btn{
                background-color: #179966;
                color: #fff;
            }
            ion-button.c-btn{
                color:#0D0D0D;
                border: 1px solid #e6e6e6;
            }
        }
    }
}
.select-pop-options{
    --max-width: calc(var(--width)*0.95);
    margin-left: calc(var(--width)*0.025);
}
.reset-modal{
    --max-height: 300px !important;
}

@media screen and (max-width: 576px) {
    ion-modal.phn-field{
        ion-header.phn-field {
            ion-toolbar{
                padding-top: 12px !important;
                padding-bottom: 20px;
                padding-inline-start: 16px;
                padding-inline-end: 16px;
                --border-width: 0;
                --background: transparent;
                --min-height: 32px !important;
                ion-title{
                    padding: 0;
                    font-size: 17px;
                    font-weight: 600;
                    color: #000;
                    text-align: center;
                    margin-top: 8px;
                }
            }
        }
    }
    .c-modal{
        --border-radius:14px;
        --width: 100%;
        &::part(content){
            position: absolute;
            bottom: -10px;
            transform: none;
        }
        &::part(backdrop) ~ div{
            position: absolute;
            bottom: -10px;
            transform: none;
        }
    }
}
.customaccount{
    --height: 600px;
    --max-width: 466px;
    --width:466px;
    --background: transparent;
    --box-shadow: 0 0 0;
    ion-content{
        --background: transparent;
        .contentdiv{
            border-radius: 16px;
            background: white;
            padding: 29px 40px 40px 40px;
        }
        .input-border{
            border: 1px solid #D0D7D4;
            margin-top: 8px;
            height: 40px;
        }
        ion-button.modalsave{
            font-weight: 600;
            font-size: 16px;
            height: 64px;
            --border-radius: 16px;
            --background:var(--main-theme);
            --background-hover:var(--main-theme-hover);;
            --background-activated:var(--main-theme-activated);
            &[disabled]{
                opacity: 1;
                --background:var(--disabled-theme);
                --background-hover:var(--disabled-theme);
                --background-activated:var(--disabled-theme);
            }
        }
        ion-button.modalcancel{
            color: var(--main-text-color);
            font-weight: 600;
            font-size: 16px;
            height: 64px;
            --border-radius: 16px;
            --background:var(--ion-color-pure-white);
            --background-hover:var(--ion-color-pure-white);
            --background-activated:var(--ion-color-pure-white);
        }
        h2{
            font-size: 28px;
            margin-top: 9px;
            margin-bottom: 4px;
            font-weight: 400;
        }
        .subheading{
            color: var(--sub-title-color);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 19px;
        }
        .modeldivider{
            margin-top: 14px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--border-four-color);
            // width: 130%; // removed on 29march 2022
            height: 1px;
            margin-left: -40px;
            margin-right: -40px;
            // --padding-end:22px;
            
        }
        .enterPercent{
            ion-item {
                --border-width: 1px; 
                --border-color: var(--border-one-color);
                --padding-start: 17px;
                margin-bottom: 16px;
                ion-icon{
                    margin-left: 0px;
                    font-size: 17px;
                }
            }
        }
    }
    @media (max-width: 39.9375em) {
        --height: 450px;
        --width:100%;
        --background: white;
        --border-radius: 12px;
        &::part(content){
            position: absolute;
            bottom: -10px;
            transform: none;
        }
        ion-content{
            .contentdiv{
                padding: 0px 16px 25px 16px;
            }
            ion-button.modalsave{
                font-weight: 600;
                font-size: 16px;
                height: 44px;
                --border-radius: 24px;
            }
            ion-button.modalcancel{
                border: 1.5px solid var(--border-four-color);
                color: var(--main-text-color);
                font-weight: 600;
                font-size: 16px;
                height: 44px;
                border-radius: 24px;
                margin-bottom: 40px;
            }
            .savecol{
                padding-bottom: 0;
            }
            .cancelcol{
                padding-top: 0;
            }
            .buttonrow{
                margin-right: 11px;
                margin-left: 11px;
            }
            .mobilehandle{
                display: flex;
                justify-content: center;
                margin: 0 auto;
                font-size: 48px;
                transform: translateY(-12px) scale(0.68);
            }
        }
    }
}
.titleicon{
    margin-right: 40px;
    transform: scale(1.55);
}
.grecaptcha-badge { visibility: hidden; }
.bx-livechat-position-bottom-right{
    bottom: 15px !important;
    right: 15px !important;
}
.b24-widget-button-visible{
    visibility: hidden !important;
}
.bx-livechat-mobile-state{
    top: calc(15px + var(--ion-safe-area-top)) !important;
}
.reviewAccount {
    .alert-message {
        white-space: pre-wrap;
    }
}
// .tippy-box{
//     background-color: #179b66;
//     padding: 12px 16px;
//     border-radius: 16px 16px 16px 0px;
//     left: 60%;
//     .tippy-content{
//         padding:0; 
//     }
//     .sheet-change{
//         font-size: 16px;
//         background-color: #179b66;
//         color: #fff;
//         font-weight: 400;
//         line-height: 24px;
//         letter-spacing: 0.3px;
//     }
//     .tippy-arrow{
//         width: 24px;
//         height: 10px;
//         color: #179b66;
//         &:before{
//             background-color: #179b66;
//             width: 24px;
//             height: 10px;
//             bottom: -17px !important;
//             left: -116.9px !important;
//             clip-path: polygon(0 0, 0% 100%, 100% 0);
//         }
//     }
// }
// @media screen and (max-width: 576px) {
//     .tippy-box{
//         left: unset;
//         padding: 8px 12px;
//         top: 6px;
//         border-radius: 16px;
//         .tippy-arrow{
//             &:before{
//                 width: 15px;
//                 height: 5px;
//                 bottom: -11px !important;
//                 left: 100% !important;
//                 clip-path: polygon(0 0, 45% 100%, 100% 0%,100% 0);
            
//             }
//         }
//     }
// }

// @-webkit-keyframes cssAnimation {
//     0%   {opacity: 1;}
//     90%  {opacity: 1;}
//     100% {opacity: 0;}
// }
// animation: cssAnimation 0s ease-in-out 400s forwards;
